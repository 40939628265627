body {
  margin: 0;
  font-family: nunito sans, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  margin: 0px;
  padding: 0px;
  font-family: nunito sans, sans-serif !important;
}
.bgcolor {
  background-color: #fafafa;
}
.main {
  display: flex;
  justify-content: center;
  background-color: #f8f9fa;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px,
    rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  width: 500px;
  height: 90vh;
  padding: 35px 0px 35px 0px;
}
.username {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  min-width: 0px;
  padding: 0px;
  border: 0px;
  vertical-align: top;
  width: 90%;
  margin: 8px;
  border-radius: 4px;
}
.password {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  min-width: 0px;
  padding: 0px;
  border: 0px;
  vertical-align: top;
  width: 90%;
  margin: 8px;
  border-radius: 4px;
}
.button-login {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  padding: 10px;
  border: 0px;
  border-radius: 4px;
  vertical-align: top;
  width: 150px;
  margin: 8px;
  background-color: rgb(25, 118, 210) !important;
  color: white !important;
}
.img_size {
  height: 50px;
  width: 120px;
}
.shadow {
  box-shadow: 0px 0px 2px 2px rgba(231, 229, 229, 0.3);
}
.header_shadow {
  box-shadow: 0 2px 3px -1px rgba(202, 39, 39, 0.3);
}
.sidenavshadow {
  box-shadow: 0px 5px 2px 2px rgba(231, 229, 229, 0.3);
}

.sub_header {
  display: flex;
  align-items: center;
}
.txt-center {
  text-align: center;
}

.revaalo {
  text-align: start;
  width: 300px;
}
.notification_contant {
  text-align: start;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px !important;
  font-weight: 600;
  padding: 6px;
}
.icon_cont {
  text-align: start;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  font-weight: 600;
  padding: 6px;
  align-items: center;
  background-color: white;
}
.icon-style {
  font-size: 1.2rem !important;
  color: black;
  padding: 1px;
  min-width: 40px;
}

.icon_size {
  font-size: 1rem !important;
  font-size: 13px;
}
.side_nav {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  color: #0a0a0a;
  position: fixed;
  text-align: center;
  font-size: 30px;
  width: 100%;
  text-align: center;
  height: 100%;
  margin: 0px !important;
  background-color: white !important;
  padding: 0px 0px 0px 0px;
  position: static !important;
}
.alignicon {
  text-align: end;
}

.medium {
  background-color: rgba(177, 171, 171, 0.359);
  border: 2px solid white;
  width: 100% !important;
  height: 100% !important;
}
.header_container {
  background-color: white;
  height: auto;
}
.toper_container {
  height: auto;
}

.card-icon-1 {
  background-color: rgb(194, 230, 218);
  height: auto;
  width: 80px;
  text-align: center;
  padding: 4px 6px;
}
.setting_icon {
  font-size: 3rem !important;
  color: rgb(10, 187, 211);
  text-align: center;
  align-items: center;
}
.card-icon-2 {
  background-color: pink;
  height: auto;
  width: 80px;
  text-align: center;
  padding: 4px 6px;
}
.view_icon {
  font-size: 3rem !important;
  color: rgb(214, 57, 52);
  text-align: center;
}
.card-icon-3 {
  background-color: rgb(240, 187, 178);
  height: auto;
  width: 80px;
  text-align: center;
  padding: 4px 6px;
}
.user_icon {
  font-size: 3rem !important;
  color: red;
  text-align: center;
}

.card_data {
  font-size: 14px;
}
.card_size {
  width: 100%;
  height: 100%;
}
.card_size_list {
  width: 100%;
  height: auto;
}
.paichart {
  height: 100%;
  width: 100%;
}
.linechart {
  height: 100%;
  width: 100%;
}
.card_flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.review_card_size {
  width: 21.2rem;
  height: auto;
  /* max-height: 250px; */
}
.active {
  background-color: #01a1de;
  color: white !important;
  font-weight: 600;
}

.toper_sub {
  height: 40px !important;
  width: 150px;
}
.star_icon {
  color: rgb(250, 175, 0);
}
.dashboard {
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 14px;
}
.textcolor {
  color: black;
  text-decoration: none !important;
}
.datepick {
  font-size: 14px;
  width: 100px;
  height: 20px !important;
  text-align: center;
}
.notification_text {
  font-size: 16px;
  display: flex;
  align-items: center;
  font-weight: 400;
}
.textcolor:hover {
  background-color: #01a1de;
  color: white;
}
.login_logo {
  height: 102px;
  width: 153px;
  margin: 1px;
}
.header_img_size {
  width: 80px;
  height: 50px;
}
.hello {
  font-weight: 700;
  font-size: 30px;
}

.blogcard {
  width: 21.2rem;
  height: auto;
}

@media screen and (max-width: 767px) {
  .blogcard {
    width: 20.8rem;
    height: auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .blogcard {
    width: 23.5rem;
    height: auto;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .blogcard {
    width: 19.8rem;
    height: auto;
  }
}
@media screen and (min-width: 1281px) and (max-width: 1367px) {
  .blogcard{
    width:21.2rem ;
    height: auto;
  }
}

@media screen and (min-width: 1558px) {
  .blogcard {
    width: 23.5rem;
    height: auto;
    /* Add any additional styles for large screens */
  }
}
@media screen and (min-width: 1558px) and (max-width:1968)  {
  .blogcard{
    width:24.5rem ;
    height: auto;
    /* Modify other styles for large screens */
  }
}

.img_radius {
  border-radius: 5px;
  width: 100%;
  height: 200px;
}
.discussion_img {
  border-radius: 5px;
  max-width: 100%;
  height: 200px;
}
.icon_size_below {
  font-size: 1.5rem !important;
  font-size: 13px;
}
.blog_condition {
  border-radius: 5px;
  width: fit-content;
  color: white;
  margin: 0;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 600;
}

.view {
  background-color: #01a1de !important;
  width: 80px;
  border-radius: 4px;
  color: white !important;
}
.cancel {
  display: flex;
  justify-content: right;
}
.cancel_button {
  background-color: #01a1de !important;
  width: 100px;
  border-radius: 4px;
  color: white !important;
}
.cencel-button {
  background-color: gray !important;
  width: 80px;
  border-radius: 4px;
  color: white !important;
}
.width-field {
  width: 100% !important;
}
.usericon {
  display: flex;
  align-items: center;
}
.requestcard {
  width: 60rem !important;
  margin: 20px !important;
}
.bordernone {
  border: none !important;
  outline: none !important;
}
.request_padding {
  padding: 50px 30px 30px 30px;
}
.blog_padding {
  padding: 30px;
}

.requestheader {
  background-color: white;
  display: flex;
  align-items: center;
}
.card-icon-center {
  display: flex;
  align-items: center;
}
.card_icon_align {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.fontsize {
  font-size: 12px;
}
.fontsize1 {
  font-size: 12px !important;
}

.title {
  font-size: 14px;
  font-weight: 100;
}
.requestedcards {
  padding: 40px 30px 30px 30px;
}
.textarea {
  height: 100px !important;
}
.services {
  background-color: white;
}

.requestheader {
  display: flex;
  align-items: center;
  background-color: white;
}
.requestbackground {
  background-color: white;
  margin-top: 30px;
  padding: 20px 20px 20px 20px;
}
.cardbodypadding {
  padding: 10px 15px 10px 15px;
}
.limitedline {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 18px;
  font-weight: 600;
}
.limitedline_shortdiscription {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 16px;
  font-weight: 400;
}
.limitedlinereview {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 14px;
  font-weight: 300;
  min-height: 65px;
}
.limitedlinetitle {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 14px;
  font-weight: 300;
}
.namelimited {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}
.aligincenter {
  display: flex;
  align-items: center;
}
.card-title {
  font-size: 16px;
  font-weight: 600;
}
.card_center {
  width: 600px;
  height: auto;
  justify-content: center;
}
.modal_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px !important;
  background-color: #fff;
  border: 2px solid #000;
  padding: 24px;
  overflow-y: scroll;
  height: 600px;
  border: none !important;
}
.review_modal_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px !important;
  background-color: #fff;
  border: 2px solid #000;
  padding: 24px;
  overflow-y: scroll;
  height: 450px;
  border: none !important;
}
.delete_modal_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: #fff;
  border: 2px solid #000;
  /* padding: 24px; */
  height: auto;
  border: 1px !important;
}
/* -------------------------------Css for Customize scroll bar start here------------------------ */
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* ---------------------End Here------------------------ */
.deletebox {
  display: flex;
  justify-content: end;
}
.aligincenter {
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  font-size: 18px;
  font-weight: 600;
}
.pagination {
  align-items: center;
  justify-content: center;
}
.delete_button_size {
  width: 100px;
}
.font_size_for_p {
  font-size: 18px;
}
.forgetpassword_modal_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px !important;
  background-color: #fff;
  border: 2px solid #000;
  padding: 24px;
  height: auto;
  border: none !important;
}
.note_size {
  font-size: 10px;
  color: red;
}
.statuscolor {
  font-weight: 400;
  font-size: 14px;
}
.notification_p_size {
  font-size: 12px;
  font-weight: 400;
  align-items: left;
   justify-content: left !important;
}

.rating_count
{
  background-color: #febe42;
  color: white;
  width: 20px;
  height: auto;
  text-align: center;
  border-radius: 3px;
  font-size: 14px;
}
.image-container {
  position: relative;
}

.image-text-overlay {
  position: absolute;
  color: black;
  background-color:white;
  border-radius: 15px;
  padding: 5px 10px; /* Adjust padding as needed */
}

.top-left-overlay {
  top: 10px;
  left: 10px;

}
.bottom-right-overlay
{
  top:80%;
  right: 7%;
}

.image-title {
  font-size: 18px;
  margin-bottom: 5px;
}

.image-description {
  font-size: 13px;
}
.text-center {
  display: flex;
  align-items: center;
  justify-content: center;
}